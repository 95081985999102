import { System } from "@lor/core-services-authorisation-web-client";
import { useHttpClient } from "@lor/core-web-httpclient";
import { useQuery } from "react-query";
import { httpClientKey } from "../helpers";

import { Health, MetadataModel } from "../models";

export const useHealthCheck = (system: System) => {

    const client = useHttpClient(httpClientKey).client;

    const healthUrl = system.healthCheckUrl;

    return useQuery(`${system.name}|health`, async () => {

        if (!healthUrl) {
            return { Status: "Unsupported", Entries: {} } as Health;
        }

        return (await client.get<Health>(healthUrl)).data;
    }, {  staleTime: 30000 });
}

export const useMetadata = (system: System) => {

    const client = useHttpClient(httpClientKey).client;

    const metadataUrl = system.metadataUrl;

    return useQuery(`${system.name}|metadata`, async () => (await client.get<MetadataModel>(metadataUrl)).data, {  staleTime: 30000 });
}

