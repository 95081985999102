import { Navigation } from "@lor/core-web-components";
import { useUserHasPermission } from "../../helpers/hooks";
import permissions from "../../helpers/permissions";
import routes from "../../routes";

const DeadLetterNavigation: React.FC = () => {

    const userHasPermission = useUserHasPermission();

    return (
        <Navigation>
            {userHasPermission(permissions.ReadMessages) && <Navigation.Route route={routes.eventGrid}>{routes.eventGrid.title}</Navigation.Route>}
            {userHasPermission(permissions.ReadMessages) && <Navigation.Route route={routes.serviceBus}>{routes.serviceBus.title}</Navigation.Route>}
        </Navigation>
    );
}

DeadLetterNavigation.displayName = "DeadLetterNavigation";

export default DeadLetterNavigation;
