import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare";
import React from "react";
import { Card } from "react-bootstrap";
import { Health } from "../../models";
import { useHealthCheck, useMetadata } from "../../services";
import { classForHealth, getConfiguration } from "../../helpers";
import { System } from "@lor/core-services-authorisation-web-client";
import { StatusIcon } from "./StatusIcon";

export const WallboardApp: React.FC<WallboardAppProps> = ({system, onShowHealthStatus}) => {

    const configuration = getConfiguration();

    const health = useHealthCheck(system);

    const metadata = useMetadata(system);

    return (
        <Card>
            <div className={`card-colour bg-${classForHealth(health?.data?.Status)}`}></div>
            <div className="app-links">
                <a href={system.url} onClick={(e) => e.stopPropagation()} title={`Go to ${system.name}`}><FontAwesomeIcon icon={faArrowUpRightFromSquare} size="lg" /></a>
                <a href={`${configuration.gatekeeperUrl}/systems/${metadata?.data?.applicationId}`} onClick={(e) => e.stopPropagation()} title="View in Gatekeeper"><img src={`${configuration.cdnBaseUrl}/images/gatekeeper.svg`} width={20} alt="View in Gatekeeper"/></a>
                <a href={`${configuration.seqEndpoint}/#/events?filter=ApplicationName = "${metadata?.data?.applicationName}" AND ApplicationEnvironment = "${configuration.applicationEnvironment}"`} onClick={(e) => e.stopPropagation()} title="Open in Seq"><img src={`${configuration.seqEndpoint}/favicon.ico`} alt="Open in Seq" width={20} height={20} /></a>
            </div>
            <Card.Body>
                <Card.Title>
                    <img src={system.logoUrl} alt={system.name} width={26} height={26} />
                    <h3>{system.shortName ? system.shortName : system.name}</h3>
                    <StatusIcon status={health?.data?.Status} onShowHealthStatus={() => onShowHealthStatus(health?.data)} />
                </Card.Title>
                <Card.Subtitle>{metadata?.data?.fileVersion}</Card.Subtitle>
            </Card.Body>
        </Card>
    );
}

WallboardApp.displayName = "WallboardApp";

export interface WallboardAppProps {
    system: System;
    onShowHealthStatus: (health?: Health) => void;
}
