import "./App.scss";

import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { createMenu, InProgress, Layout, Router, ToastPosition, ToastProvider } from "@lor/core-web-components";
import { useApps } from "@lor/core-services-apps-clientservices";
import { useAuth0 } from "@auth0/auth0-react";
import { LinkProps, MenuItem } from "@lor/core-web-types";
import { useTasksCount } from "@lor/core-web-mytasksreactsdk";

import { AppDisplayName, getConfiguration, getCurrentVersion, logo } from "./helpers";
import routes from "./routes";

import userPermissions from "./helpers/permissions";
import { useUserHasPermission } from "./helpers/hooks";

const App: React.FC = () => {
    const {
        loginWithRedirect,
        logout,
        isAuthenticated,
        isLoading
    } = useAuth0();

    const myTasksTotal = useTasksCount();

    useEffect(() => {
        if (!isAuthenticated && !isLoading) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    const mainMenu: MenuItem[] = useMenuItems();

    const menuItems = createMenu("", mainMenu, routes);

    const apps: LinkProps[] = [{
        title: "Dev Docs",
        href: "https://devdocs.laingorourke.com.au",
        logo: "https://cdn.laingorourke.com.au/images/devdocs.svg"
    }, ...useApps(getConfiguration().appsService.baseUrl)];

    return (
        <BrowserRouter>
            <InProgress loading={false} />
            {!isAuthenticated && <img src={logo} className="launch-logo" alt={AppDisplayName} />}
            {isAuthenticated &&
                <ToastProvider position={ToastPosition.TopRight} >
                    <Layout>
                        <Layout.Main>
                            <Layout.Header
                                sticky
                                brand={{
                                    logo: logo,
                                    name: AppDisplayName
                                }}
                                tasksBadge={{
                                    count: myTasksTotal.data?.numberOfTodos,
                                    link: getConfiguration().myTasksConfig.baseUrl!,
                                }}
                                appLauncher={{
                                    apps: apps,
                                    supportLinks: {
                                        contactUsHref: "mailto:dl-it-applicationdevelopment@laingorourke.com.au",
                                    },
                                }}
                                navigation={menuItems}
                                userMenu={{
                                    onLogout: () => logout({ returnTo: window.location.origin }),
                                }}>
                            </Layout.Header>
                            <Router
                                routes={routes}
                                appName={AppDisplayName}
                                redirects={[]}>
                            </Router>
                        </Layout.Main>
                        <Layout.Footer copyrightYear={2021} version={getCurrentVersion()} appDisplayName={AppDisplayName} />
                    </Layout>
                </ToastProvider>
            }
        </BrowserRouter>
    );
}

export default App;

const useMenuItems = (): MenuItem[] => {

    const menuItems: MenuItem[] = [];

    const permissionCheck = useUserHasPermission();

    if (permissionCheck(userPermissions.ViewAppStatus)) menuItems.push({ text: routes.apps.title!, path: routes.apps.path });
    if (permissionCheck(userPermissions.ReadMessages)) menuItems.push({ text: routes.deadLetters.title!, path: routes.deadLetters.path });
    if (permissionCheck(userPermissions.DevToolsRead)) menuItems.push({ text: routes.devTools.title!, path: routes.devTools.path });

    return menuItems;
}

