import { useMutation, UseMutationOptions } from "react-query";
import { useHttpClient } from "@lor/core-web-httpclient";
import { httpClientKey } from "../helpers";
import { AxiosResponse } from "axios";

export const useApiDelete = <Variables>(path: (variables: Variables) => string, options?: UseMutationOptions<AxiosResponse<null>, null, Variables>) => {

    const httpClient = useHttpClient(httpClientKey);

    return useMutation<AxiosResponse<null>, null, Variables>(async (variables): Promise<AxiosResponse<null>> => await httpClient.client.delete(path(variables)), options);
}

export const useApiDeleteWithBody = <Variables>(path: (variables: Variables) => string, options?: UseMutationOptions<AxiosResponse<null>, null, Variables>) => {

    const httpClient = useHttpClient(httpClientKey);

    return useMutation<AxiosResponse<null>, null, Variables>(async (variables): Promise<AxiosResponse<null>> => await httpClient.client.delete(path(variables), {
        data: variables
    }), options);
}
