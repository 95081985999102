import { Button } from "react-bootstrap";
import { EventGridDeadLetter } from "../../models";
import { Drawer } from "@lor/core-web-components";

export const EventGridDeadLetterDetails: React.FC<EventGridDeadLetterDetailsProps> = ({ deadLetter, ...props }) => deadLetter ? (
    <Drawer {...props} title={`${deadLetter.topicName} - ${deadLetter.subscription}`} push actions={[<Button onClick={props.onChange}>Close</Button>]} showCloseIcon closeOnClickAway width={700} className="dead-letters-pop-up">
        <>
            <h4>Data</h4>
            <textarea value={deadLetter.data && JSON.stringify(deadLetter.data, undefined, 4)} className="dead-letter-editor" />
        </>
    </Drawer>
) : <></>;

export interface EventGridDeadLetterDetailsProps {
    deadLetter?: EventGridDeadLetter;
    onChange: () => void;
    open: boolean;
}
