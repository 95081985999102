import "./Convert.scss";

import { DOMParser as ProseMirrorDOMParser } from "prosemirror-model";
import { BuiltInSchema } from "@lor/core-web-richtexteditor"
import Navigation from "./DevToolsNavigation";
import { Button, Form, Page, ToastMessageType, useToasts } from "@lor/core-web-components";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { useUserHasPermission } from "../../helpers/hooks";
import permissions from "../../helpers/permissions";

export const Convert = () => {

    const userHasPermission = useUserHasPermission();

    const [input, setInput] = useState("");
    const [output, setOutput] = useState("");

    const toasts = useToasts();

    const htmlToProseMirror = () => {

        const domNode = new DOMParser().parseFromString(`<div>${input}</div>`, "text/html");

        const parser = ProseMirrorDOMParser.fromSchema(BuiltInSchema)

        const pmNode = parser.parse(domNode);

        setOutput(JSON.stringify(pmNode.toJSON()));
    }

    const copyToClipboard = () => {
        output && navigator.clipboard.writeText(output);
        toasts.newMessage({
            title: "Copied to clipboard",
            type: ToastMessageType.Information
        });
    }

    useEffect(() => {
        htmlToProseMirror();
    }, [input]);

    useEffect(() => {
        copyToClipboard();
    }, [output]);

    // As this page is client-side only, do not show UI if the user does not have permssion.
    if (!userHasPermission(permissions.DevToolsRead)) return null;

    return (
        <Page title="Convert" header={<Page.Header title="Convert"><Navigation /></Page.Header>}>
            <Page.Section>
                <header>
                    <h2>Convert HTML to ProseMirror</h2>
                    <Button variant="primary" onClick={() => htmlToProseMirror()}>Convert</Button>
                </header>
                <section className="form">
                    <Form.Group className="form-group form-field">
                        <label htmlFor="input" className="field-label form-label">HTML to convert</label>
                        <textarea id="input" className="form-control convert-input" value={input} onChange={(e) => setInput(e.currentTarget.value)} onClick={(e) => e.currentTarget.select()} />
                    </Form.Group>
                    <Form.Group className="form-field">
                        <label htmlFor="input" className="field-label form-label">ProseMirror JSON&nbsp;<FontAwesomeIcon icon={faCopy} className="clickable" onClick={copyToClipboard} title="Copy to clipboard" /></label>
                        <pre className="convert-output">{output}</pre>
                    </Form.Group>
                </section>
            </Page.Section>
        </Page>
    );
}
