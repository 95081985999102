import { Auth0Provider } from "@auth0/auth0-react";
import { configureApiCheck, HttpClientProvider } from "@lor/core-web-httpclient";
import { MyTasksProvider } from "@lor/core-web-mytasksreactsdk";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query"

import App from "./App";
import { populateConfiguration, getConfiguration, httpClientKey, logo } from "./helpers";
import { ErrorPage } from "./pages";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<img
    className="launch-logo"
    alt="Ground Control Logo"
    src={logo}
/>,
    document.getElementById("root")
);

populateConfiguration().then(() => {
    const config = getConfiguration();
    const auth0Config = config?.auth;
    const myTasksConfig = config?.myTasksConfig;

    const queryClient = new QueryClient();

    ReactDOM.render(
        <React.StrictMode>
            <Auth0Provider audience={auth0Config.audience} clientId={auth0Config.clientId} domain={auth0Config.domain} redirectUri={window.location.origin}>
                <HttpClientProvider resources={[{ key: httpClientKey, baseUrl: "/api", configure: configureApiCheck, audience: auth0Config.audience }]}>
                    <MyTasksProvider baseUrl={`${myTasksConfig?.baseUrl!}/api`} audience={myTasksConfig?.audience}>
                        <QueryClientProvider client={queryClient}>
                            <App />
                        </QueryClientProvider>
                    </MyTasksProvider>
                </HttpClientProvider>

            </Auth0Provider>
        </React.StrictMode>,
        document.getElementById("root")
    );
}).catch((error: Error) => {
    ReactDOM.render(<ErrorPage message={error.message} />, document.getElementById("root"));
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
