import "./GetToken.scss";

import { Button, Page, Select, ToastMessageType, useToasts } from "@lor/core-web-components";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Navigation from "./DevToolsNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { getConfiguration } from "../../helpers";
import { useResourceServers } from "../../services";
import { ValueOption } from "./ValueOption";
import { OptionTypeBase } from "@lor/core-web-components/dist/Form/SelectProps";
import { useM2MClients } from "../../services/clients";

export const GetM2MToken = () => {

    const config = getConfiguration();

    const [audience, setAudience] = useState<string>("");
    const [clientId, setClientId] = useState<string>("");
    const [clientSecret, setClientSecret] = useState<string>("");
    const [token, setToken] = useState<string>();

    const resourceServers = useResourceServers();
    const m2mClients = useM2MClients();

    const generate = async () => {

        const result = await fetch(`https://${config.auth.domain}/oauth/token`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: new URLSearchParams({
                grant_type: "client_credentials",
                client_id: clientId,
                client_secret: clientSecret,
                audience: audience
            })
        });

        const resultBody = await result.json();

        setToken(resultBody.access_token);
    }

    const toasts = useToasts();

    const copyToClipboard = () => {
        token && navigator.clipboard.writeText(token);
        toasts.newMessage({
            title: "Copied to clipboard",
            type: ToastMessageType.Information
        });
    }

    return (
        <Page className="get-token" header={<Page.Header title="Get M2M Token"><Navigation /></Page.Header>}>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Select label="Destination API..." options={resourceServers.data?.map<OptionTypeBase>(r => ({ value: r.identifier, label: r.name }))} value={audience} onChange={(e) => setAudience(e as string)}
                            components={{
                                Option: ValueOption
                            }} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Select label="Client..." options={m2mClients.data?.map<OptionTypeBase>(r => ({ value: r.id, label: r.name }))} value={clientId} onChange={(e) => setClientId(e as string)}
                            components={{
                                Option: ValueOption
                            }} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <input type="password" placeholder="Client Secret" onChange={(e) => setClientSecret(e.currentTarget.value)} value={clientSecret} className="input form-control" />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Form.Group>
                        <Button variant="primary" onClick={generate}>Generate</Button>
                    </Form.Group>
                </Col>
            </Row>
            {token &&
                <>
                    <h2>Access Token <FontAwesomeIcon icon={faCopy} className="clickable" onClick={copyToClipboard} title="Copy to clipboard" /></h2>
                    <Page.Section className="token">
                        {token}
                    </Page.Section>
                </>
            }
        </Page>
    );
}
