import { PagedResult } from "@lor/core-web-types";
import { useQueryClient } from "react-query";
import { EventGridDeadLetter, EventGridResubmitRequest, ServiceBusDeadLetter } from "../models";
import { useApiDelete, useApiDeleteWithBody } from "./useApiDelete";
import { useApiPost } from "./useApiPost";
import { useApiQuery } from "./useApiQuery";

export const useEventGridDeadLetters = (currentPage: number, pageSize: number, sortColumn: string, sortDirection: string) => useApiQuery<PagedResult<EventGridDeadLetter>>(["deadletters/event-grid", currentPage, pageSize, sortColumn, sortDirection], `/deadletters/event-grid/${currentPage}/${pageSize}/${sortColumn}/${sortDirection}`);

export const useServiceBusDeadLetters = () => useApiQuery<ServiceBusDeadLetter[]>(["deadletters/service-bus"], `/deadletters/service-bus`, {
    staleTime: 30000
});

export const useDeleteServiceBusDeadLetter = () => {

    const queryClient = useQueryClient();

    return useApiDelete<ServiceBusDeadLetter>((variables) => `/deadletters/service-bus/${variables.topicOrQueue}/${variables.id}`, {
        onMutate: (deadLetter) => {
            const data = queryClient.getQueryData<ServiceBusDeadLetter[]>(["deadletters/service-bus"]);

            queryClient.setQueryData(["deadletters/service-bus"], data?.filter(s => s.id !== deadLetter.id));
        },
        onSettled: () => {
            queryClient.invalidateQueries(["deadletters/service-bus"]);
        }
    });
}

export const useClearServiceBusDeadLetters = () => {

    const queryClient = useQueryClient();

    return useApiDelete<null>(() => `/deadletters/service-bus`, {
        onMutate: () => {
            queryClient.setQueryData(["deadletters/service-bus"], []);
        },
        onSettled: () => {
            queryClient.invalidateQueries(["deadletters/service-bus"]);
        }
    });
}

export const useDeleteEventGridDeadLetters = () => {

    const queryClient = useQueryClient();

    return useApiDeleteWithBody<string[]>(() => `/deadletters/event-grid/bypath`, {
    //?${variables.map(v => `path=${encodeURIComponent(v.path)}`).join("&")}`, {
        onSettled: () => {
            queryClient.invalidateQueries(["deadletters/event-grid"]);
        }
    });
}

export const useClearEventGridDeadLetters = () => {

    const queryClient = useQueryClient();

    return useApiDelete<null>(() => `/deadletters/event-grid`, {
        onSettled: () => {
            queryClient.invalidateQueries(["deadletters/event-grid"]);
        }
    });
}

export const useResubmitEventGrid = () => {

    const queryClient = useQueryClient();

    return useApiPost<null, null, EventGridResubmitRequest>(() => "deadletters/event-grid", {
        onSettled: () => {
            queryClient.invalidateQueries(["deadletters/event-grid"]);
        }
    })
}
