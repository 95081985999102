import { Button, ColumnDefinition, Page, Table } from "@lor/core-web-components";

import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import format from "date-fns/format";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { getConfiguration } from "../../helpers";
import { useUserHasPermission } from "../../helpers/hooks";
import userPermissions from "../../helpers/permissions";
import { ServiceBusDeadLetter } from "../../models";
import Navigation from "./Navigation";
import { useClearServiceBusDeadLetters, useDeleteServiceBusDeadLetter, useServiceBusDeadLetters } from "../../services/deadletters";
import { ServiceBusDeadLetterDetails } from "./ServiceBusDeadLetterDetails";

export const ServiceBus: React.FC = () => {

    const seqEndpoint = getConfiguration().seqEndpoint;

    const columns: ColumnDefinition<ServiceBusDeadLetter>[] = [
        {
            text: "Service Bus",
            field: row => row.serviceBus,
            sortable: true,
            headerClass: "column-10"
        },
        {
            text: "Topic or Queue",
            field: row => row.topicOrQueue,
            sortable: true,
            headerClass: "column-20"
        },
        {
            text: "Enqueued Time",
            field: row => format(new Date(row.enqueuedTime), "yyyy-MM-dd HH:mm:ss"),
            sortable: true,
            headerClass: "column-15"
        },
        {
            text: "Application ID",
            field: row => row.applicationId,
            sortable: true,
            headerClass: "column-25"
        },
        {
            text: "Correlation ID",
            field: row => row.correlationId,
            sortable: true,
            headerClass: "column-25"
        },
        {
            text: "",
            field: row => (
                <div onClick={(e) => e.stopPropagation()}>
                    {permissionCheck(userPermissions.DeleteMessages) && <span onClick={() => deleteMessage(row)}><FontAwesomeIcon icon={faTrashAlt} size="lg" /></span>}
                    {row.correlationId ? <span><a href={`${seqEndpoint}/#/events?filter=CorrelationId%20=%20%22${row.correlationId}%22`} rel="noreferrer" target="_blank" onClick={(e) => e.stopPropagation()}><img src={`${seqEndpoint}/favicon.ico`} alt="Open in Seq" title="Open in Seq" width={24} height={24} /></a></span> : <></>}
                </div>
            ),
            sortable: false,
            headerClass: "column-5",
            cellClass: "action-panel"
        },
    ];

    const [showDetails, setShowDetails] = useState(false);
    const [selectedDeadLetter, setSelectedDeadLetter] = useState<ServiceBusDeadLetter>();

    const { data, isLoading } = useServiceBusDeadLetters();
    const deleteServiceBusDeadLetter = useDeleteServiceBusDeadLetter();
    const clearServiceBusDeadLetters = useClearServiceBusDeadLetters();

    const permissionCheck = useUserHasPermission();

    const deleteMessage = (deadLetter: ServiceBusDeadLetter) => {
        if (window.confirm("Are you sure you want to delete this dead letter? "))
        {
            deleteServiceBusDeadLetter.mutate(deadLetter);
        }
    }

    const deleteAllMessages = () => {
        if (window.confirm("Are you sure you want to delete ALL dead letters? This is irreversible!"))
        {
            clearServiceBusDeadLetters.mutate(null);
        }
    }

    return (<Page header={<Page.Header title="Service Bus" actions={[<Button variant="danger" hidden={!permissionCheck(userPermissions.ClearMessages)} onClick={deleteAllMessages}>Delete All</Button>]}><Navigation /></Page.Header>}>
        <Row>
            <Col>
                <ServiceBusDeadLetterDetails deadLetter={selectedDeadLetter} open={showDetails} onChange={() => { setShowDetails(false) }} />
                <Table
                    clickable
                    loading={isLoading}
                    noDataMessage="There are currently no dead letters"
                    skeletonRows={10}
                    pageSize={10}
                    onRowClick={(row) => {
                        setSelectedDeadLetter(row);
                        setShowDetails(true);
                    }}
                    sortColumnIndex={2}
                    columns={columns}
                    keySelector={data => `${data.serviceBus}-${data.id}`} // Ensure the ID is unique across Service Bus instances
                    data={data || []} />
            </Col>
        </Row>
    </Page>);
}
