import "./Wallboard.scss";

import { Page } from "@lor/core-web-components";
import * as auth from "@lor/core-services-authorisation-web-client";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { WallboardApp } from "./WallboardApp";
import { HealthReport } from "./HealthReport";
import { Health } from "../../models";
import { getConfiguration } from "../../helpers";
import { httpClientKey } from "@lor/core-web-mytasksreactsdk";
import { System } from "@lor/core-services-authorisation-web-client";

export const Wallboard: React.FC = () => {

    const config = getConfiguration();
    const systems = auth.useSystemsDetails(config.authServiceHost, config.cdnBaseUrl, httpClientKey, 26, false);

    const [selectedApp, setSelectedApp] = useState<System>();
    const [selectedAppHealth, setSelectedAppHealth] = useState<Health>();
    const [showHealthReport, setShowHealthReport] = useState(false);

    return (
        <Page header={{ title: "Monitoring" }}>
            <HealthReport system={selectedApp} appHealth={selectedAppHealth} open={showHealthReport} onChange={() => setShowHealthReport(false)} />
            <Row className="wallboard">
                {systems?.map(s =>
                    <Col md={6} sm={12} lg={6} xl={3} key={s.name}>
                        <WallboardApp system={s} onShowHealthStatus={(health) => {
                            setSelectedApp(s);
                            setSelectedAppHealth(health);
                            setShowHealthReport(true);
                        }} />
                    </Col>
                )}
            </Row>
        </Page>
    );
}
