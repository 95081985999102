import { Navigation } from "@lor/core-web-components";
import { useUserHasPermission } from "../../helpers/hooks";
import permissions from "../../helpers/permissions";
import routes from "../../routes";

const DevToolsNavigation: React.FC = () => {

    const userHasPermission = useUserHasPermission();

    return (
        <Navigation>
            {userHasPermission(permissions.DevToolsRead) && <Navigation.Route route={routes.getToken}>{routes.getToken.title}</Navigation.Route>}
            {userHasPermission(permissions.DevToolsRead) && <Navigation.Route route={routes.getM2MToken}>{routes.getM2MToken.title}</Navigation.Route>}
            {userHasPermission(permissions.DevToolsWrite) && <Navigation.Route route={routes.sendEvent}>{routes.sendEvent.title}</Navigation.Route>}
            {userHasPermission(permissions.DevToolsRead) && <Navigation.Route route={routes.convert}>{routes.convert.title}</Navigation.Route>}
        </Navigation>
    );
}

DevToolsNavigation.displayName = "DevToolsNavigation";

export default DevToolsNavigation;
