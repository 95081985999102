import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@lor/core-web-components";
import { classForHealth, iconForStatus, titleForStatus } from "../../helpers";
import { HealthStatus } from "../../models";

export const StatusIcon: React.FC<StatusIconProps> = ({ status, onShowHealthStatus }) => {

    if (status === "Healthy") return <></>;

    const onClick = () => {
        status !== "Unsupported" && onShowHealthStatus();
    };

    const clickable = status === "Unsupported" ? "" : "clickable" ;

    return (
        <FontAwesomeIcon icon={iconForStatus(status)} size="lg" title={titleForStatus(status)} className={classNames(clickable, ` text-${classForHealth(status)}`)} onClick={onClick} />
    );
}

export interface StatusIconProps {
    status?: HealthStatus;
    onShowHealthStatus: () => void;
}
