import { OptionTypeBase } from "@lor/core-web-components/dist/Form/SelectProps";

type CustomOptionArgs = { innerProps: OptionTypeBase, isDisabled: boolean, label: string, value: string };

export const ValueOption = (args: CustomOptionArgs) => {
    const {innerProps, isDisabled, label, value } = args;
    return !isDisabled ? (
        <div className="option" {...innerProps}><strong>{label}</strong> ({value})</div>
    ) : null;
};
